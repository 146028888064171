import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface Props {
  className: string;
  toggleMenu: Function;
  menuIsActive: boolean;
}

export default function Navbar(props: Props) {
  return (
    <>
      <div className={`${props.className}`}>
        <div className="fixed right-10 top-6 z-30">
          {/* menu icon --> fontawesome */}
          <FontAwesomeIcon
            icon={faBars}
            className={`text-white text-[2rem] ${
              props.menuIsActive
                ? 'opacity-0 transition-opacity duration-200'
                : ''
            }`}
            onClick={() => {
              props.toggleMenu();
            }}
          />
        </div>
      </div>
    </>
  );
}
