import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// context
import { AppProvider } from './context/AppContext';

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  container
);
