import React from 'react';

export default function Footer() {
  return (
    <>
      <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 justify-center items-center md:items-baseline pt-10 pb-2 text-white">
        {/* copyright */}
        <div className="text-sm xl3:text-[1.6rem]">
          <p>© 2020 by HerlanderArt | New York, NY</p>
        </div>

        {/* social media icons */}
        <div className="space-x-4 text-[1.2rem] xl3:text-[2.2rem]">
          {/* facebook link */}
          <a
            href="https://www.facebook.com/HerlanderArt/"
            target={'_blank'}
            rel="noreferrer"
            className="text-gray-400"
          >
            <i className="fab fa-facebook-f fa-lg"></i>
          </a>

          {/* instagram link */}
          <a
            href="https://www.instagram.com/herlanderart/"
            target={'_blank'}
            rel="noreferrer"
            className="text-gray-400"
          >
            <i className="fab fa-instagram fa-lg"></i>
          </a>
        </div>
      </div>
    </>
  );
}
