import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// images
import logo from '../../assets/smallLogo.webp';

interface Props {
  className?: string;
}

export default function Sidebar(props: Props) {
  let currentPath = useLocation().pathname;

  return (
    <>
      <div className={`${props.className} fixed z-30`}>
        <div className="pl-2 xl:pl-8 xl2:pl-16 xl3:pl-24 pt-12">
          <div className="flex flex-col font-main font-thin lg:text-[1.2rem] xl2:text-[2rem] xl3:text-[2rem] text-white">
            <img
              className={currentPath === '/' ? 'opacity-0' : ''}
              src={logo}
              loading="lazy"
              alt="company logo"
            />
            <div className="flex flex-col pl-7 space-y-2">
              <Link
                to="/"
                className={currentPath === '/' ? 'text-neutral-500' : ''}
              >
                Home
              </Link>
              <Link
                to="/work"
                className={currentPath === '/work' ? 'text-neutral-500' : ''}
              >
                Work
              </Link>
              <Link
                to="/about"
                className={currentPath === '/about' ? 'text-neutral-500' : ''}
              >
                About
              </Link>
              <Link
                to="/contact"
                className={currentPath === '/contact' ? 'text-neutral-500' : ''}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
