import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className: string;
  menuIsActive: boolean;
  toggleMenu: Function;
}

export default function MenuPopup(props: Props) {
  return (
    <>
      <div className={`${props.className}`} onClick={() => props.toggleMenu()}>
        {/* menu popup */}
        <div
          className={`fixed ${
            props.menuIsActive ? '' : 'hidden'
          } min-h-full min-w-full bg-gradient-to-t from-black/[0.98] via-black/[0.95] text-white text-[2.4rem] flex flex-col justify-end items-center pb-20 space-y-2 z-10`}
        >
          <Link to="/">Home</Link>
          <Link to="/work">Work</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </>
  );
}
