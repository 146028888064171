import { useNavigate } from 'react-router-dom';

// image import
import image1 from '../../assets/woodTable.webp';
import image2 from '../../assets/retail/Alice/image1.webp';
import image3 from '../../assets/home/Annalise/image3.webp';
import image4 from '../../assets/fashion/AliceFall20/image6.webp';

import logo from '../../assets/logo.webp';

export default function Home() {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center font-main">
      <div className="flex flex-col  justify-center items-center xl2:max-w-[80%] xl3:max-w-[60%]">
        {/* logo */}
        <div className="flex justify-center items-center pt-16 pb-8">
          <img
            src={logo}
            alt="company logo"
            className="max-w-full lg:max-w-[39rem] xl3:max-w-[60rem]"
          />
        </div>

        {/* words */}
        <div className="max-w-[85%] text-white text-xl space-y-6 text-center">
          <div className="flex flex-col items-center justify-center space-y-3 pb-2">
            <p className="font-bold text-[1.4rem] xl3:text-[2.4rem]">
              We are HerlanderArt.
            </p>
            <p className="font-light text-gray-400 text-[0.9rem] xl3:text-[1.65rem] max-w-[90%]">
              We design. We make furniture. We collaborate.
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            <p className="md:max-w-[80%] lg:max-w-[65%] xl:max-w-[55%] text-[1rem] smd:text-[1.3rem] xl3:text-[2rem] leading-[2.4rem]">
              In our studio, we work hard to fulfill your vision. Whether it be
              a new standing desk for your home or office, a display fixture for
              your business, or simply an idea you want to bring to life.
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="font-bold text-[1.2rem] xl3:text-[1.8rem]">
              {' '}
              We’d love to hear about it.
            </p>
          </div>
        </div>

        {/* learn more button */}
        <div className="flex justify-center items-center">
          <div className="font-main font-extralight py-10">
            <button
              className="bg-white py-2 px-7 xl3:py-3 xl3:px-9 xl3:text-[1.6rem] transition duration-500 hover:bg-black hover:text-white"
              onClick={() => {
                navigate('/work');
              }}
            >
              Learn More
            </button>
          </div>
        </div>

        {/* images */}
        <div className="flex justify-center items-center">
          <div className="carousel w-[80%] lg:w-[60%] xl:w-[50%]">
            <div id="slide1" className="carousel-item relative w-full">
              <img
                src={image1}
                alt="custom table"
                className="w-full h-[25rem] object-cover"
                loading="lazy"
              />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a
                  href="#slide4"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❮
                </a>
                <a
                  href="#slide2"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❯
                </a>
              </div>
            </div>
            <div id="slide2" className="carousel-item relative w-full">
              <img
                src={image2}
                alt="fashion outfits"
                className="w-full h-[25rem] object-cover"
                loading="lazy"
              />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a
                  href="#slide1"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❮
                </a>
                <a
                  href="#slide3"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❯
                </a>
              </div>
            </div>
            <div id="slide3" className="carousel-item relative w-full">
              <img
                src={image3}
                alt="wood table"
                className="w-full h-[25rem] object-cover"
                loading="lazy"
              />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a
                  href="#slide2"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❮
                </a>
                <a
                  href="#slide4"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❯
                </a>
              </div>
            </div>
            <div id="slide4" className="carousel-item relative w-full">
              <img
                src={image4}
                alt="modals on train"
                className="w-full h-[25rem] object-cover"
                loading="lazy"
              />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a
                  href="#slide3"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❮
                </a>
                <a
                  href="#slide1"
                  className="btn btn-circle bg-black text-gray-400"
                >
                  ❯
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
