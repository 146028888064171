import { useState, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// components
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import MenuPopup from './components/menuPopup/MenuPopup';

// pages
import Home from './pages/home/Home';
const Work = lazy(() => import('./pages/work/Work'));
const About = lazy(() => import('./pages/about/About'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Office = lazy(() => import('./pages/office/Office'));
const Retail = lazy(() => import('./pages/retail/Retail'));
const Fashion = lazy(() => import('./pages/fashion/Fashion'));
const WorkHome = lazy(() => import('./pages/workhome/WorkHome'));

const renderLoader = () => <p>Loading</p>;

function App() {
  const [menuIsActive, setMenuIsActive] = useState<boolean>(false);
  const toggleMenu = () => setMenuIsActive(!menuIsActive);

  return (
    <BrowserRouter>
      {/* sidebar */}
      <Navbar
        className="inline lg:hidden"
        toggleMenu={toggleMenu}
        menuIsActive={menuIsActive}
      />
      <Sidebar className="hidden lg:inline" />

      {/* menu popup --> mobile */}
      <MenuPopup
        className=""
        menuIsActive={menuIsActive}
        toggleMenu={toggleMenu}
      />

      {/* routes */}
      <Suspense fallback={renderLoader}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/office" element={<Office />} />
          <Route path="/retail" element={<Retail />} />
          <Route path="/fashion" element={<Fashion />} />
          <Route path="/home" element={<WorkHome />} />
        </Routes>
      </Suspense>

      {/* footer */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
