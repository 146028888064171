import { createContext, ReactNode } from 'react';
import type { AppContextType } from '../@types/AppContextTypes';

// image imports //
// office ->  gotenna
import topImage from '../assets/office/goTenna/top.webp';
import mid0Image from '../assets/office/goTenna/mid0.webp';
import mid1Image from '../assets/office/goTenna/mid1.webp';
import mid10Image from '../assets/office/goTenna/mid10.webp';
import mid11Image from '../assets/office/goTenna/mid11.webp';
import bottom0Image from '../assets/office/goTenna/bottom0.webp';
import bottom1Image from '../assets/office/goTenna/bottom1.webp';
// office -> alice
import image1A from '../assets/office/Alice & Olivia/image1.webp';
import image2A from '../assets/office/Alice & Olivia/image2.webp';
import image3A from '../assets/office/Alice & Olivia/image3.webp';
// office -> Bklyn
import image1B from '../assets/office/Bklyn/image1.webp';
import image2B from '../assets/office/Bklyn/image2.webp';
import image3B from '../assets/office/Bklyn/image3.webp';
import image4B from '../assets/office/Bklyn/image4.webp';
import image5B from '../assets/office/Bklyn/image5.webp';
import image6B from '../assets/office/Bklyn/image6.webp';
// office -> Metro
import image1M from '../assets/office/Metro/image1.webp';
import image2M from '../assets/office/Metro/image2.webp';
// retail -> alice
import image1RA from '../assets/retail/Alice/image1.webp';
import image2RA from '../assets/retail/Alice/image2.webp';
import image3RA from '../assets/retail/Alice/image3.webp';
// retail -> ballers
import image1RB from '../assets/retail/Ballers/image1.webp';
import image2RB from '../assets/retail/Ballers/image2.webp';
import image3RB from '../assets/retail/Ballers/image3.webp';
import image4RB from '../assets/retail/Ballers/image4.webp';
// retail -> century
import image1RC from '../assets/retail/Century/image1.webp';
import image2RC from '../assets/retail/Century/image2.webp';
import image3RC from '../assets/retail/Century/image3.webp';
import image4RC from '../assets/retail/Century/image4.webp';
import image5RC from '../assets/retail/Century/image5.webp';
// retail -> fourteenjay
import image1RF from '../assets/retail/Fourteenjay/image1.webp';
import image2RF from '../assets/retail/Fourteenjay/image2.webp';
import image3RF from '../assets/retail/Fourteenjay/image3.webp';
import image4RF from '../assets/retail/Fourteenjay/image4.webp';
import image5RF from '../assets/retail/Fourteenjay/image5.webp';
import image6RF from '../assets/retail/Fourteenjay/image6.webp';
// retail -> sofi
import image1RS from '../assets/retail/Sofi/image1.webp';
import image2RS from '../assets/retail/Sofi/image2.webp';
import image3RS from '../assets/retail/Sofi/image3.webp';
import image4RS from '../assets/retail/Sofi/image4.webp';
import image5RS from '../assets/retail/Sofi/image5.webp';
// fashion -> AliceFall18
import image1FA18 from '../assets/fashion/AliceFall18/image1.webp';
import image2FA18 from '../assets/fashion/AliceFall18/image2.webp';
import image3FA18 from '../assets/fashion/AliceFall18/image3.webp';
import image4FA18 from '../assets/fashion/AliceFall18/image4.webp';
import image5FA18 from '../assets/fashion/AliceFall18/image5.webp';
import image6FA18 from '../assets/fashion/AliceFall18/image6.webp';
// fashion -> AliceFall20
import image1FA20 from '../assets/fashion/AliceFall20/image1.webp';
import image2FA20 from '../assets/fashion/AliceFall20/image2.webp';
import image3FA20 from '../assets/fashion/AliceFall20/image3.webp';
import image4FA20 from '../assets/fashion/AliceFall20/image4.webp';
import image5FA20 from '../assets/fashion/AliceFall20/image5.webp';
import image6FA20 from '../assets/fashion/AliceFall20/image6.webp';
// fashion -> AliceSpring17
import image1FA17 from '../assets/fashion/AliceSpring17/image1.webp';
import image2FA17 from '../assets/fashion/AliceSpring17/image2.webp';
import image3FA17 from '../assets/fashion/AliceSpring17/image3.webp';
import image4FA17 from '../assets/fashion/AliceSpring17/image4.webp';
// fashion -> AliceSpring19
import image1FA19 from '../assets/fashion/AliceSpring19/image1.webp';
import image2FA19 from '../assets/fashion/AliceSpring19/image2.webp';
import image3FA19 from '../assets/fashion/AliceSpring19/image3.webp';
import image4FA19 from '../assets/fashion/AliceSpring19/image4.webp';
import image5FA19 from '../assets/fashion/AliceSpring19/image5.webp';
import image6FA19 from '../assets/fashion/AliceSpring19/image6.webp';
// fashion -> Booking
import image1FB from '../assets/fashion/Booking/image1.webp';
import image2FB from '../assets/fashion/Booking/image2.webp';
import image3FB from '../assets/fashion/Booking/image3.webp';
import image4FB from '../assets/fashion/Booking/image4.webp';
import image5FB from '../assets/fashion/Booking/image5.webp';
import image6FB from '../assets/fashion/Booking/image6.webp';
import image7FB from '../assets/fashion/Booking/image7.webp';
// fashion -> Haring
import image1FH from '../assets/fashion/Haring/image1.webp';
import image2FH from '../assets/fashion/Haring/image2.webp';
import image3FH from '../assets/fashion/Haring/image3.webp';
// home -> Annalise
import image1HA from '../assets/home/Annalise/image1.webp';
import image2HA from '../assets/home/Annalise/image2.webp';
import image3HA from '../assets/home/Annalise/image3.webp';
import image4HA from '../assets/home/Annalise/image4.webp';
// home -> Credenza
import image1HC from '../assets/home/Credenza/image1.webp';
import image2HC from '../assets/home/Credenza/image2.webp';
import image3HC from '../assets/home/Credenza/image3.webp';
import image4HC from '../assets/home/Credenza/image4.webp';
// home -> Stony
import image1HS from '../assets/home/Stony/image1.webp';
import image2HS from '../assets/home/Stony/image2.webp';

// create context provider
export const AppContext = createContext<AppContextType>({});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return <AppContext.Provider value={AppData}>{children}</AppContext.Provider>;
};

const AppData: AppContextType = {
  officeData: [
    {
      headerText: 'goTenna Headquarters | Brooklyn NY',
      description:
        'goTenna’s headquarters in Brooklyn showcase sustainable office furniture designed and built from locally sourced walnut hardwood and plywood. Finished in a clear oil finish to compliment the light-filled open workspaces. The unique lunch table design is supported by benches made from plywood edge with contrasting walnut.',
      materials: ['Walnut and Plywood'],
      images: [
        topImage,
        mid0Image,
        mid1Image,
        mid10Image,
        mid11Image,
        bottom0Image,
        bottom1Image,
      ],
    },
    {
      headerText: 'Alice & Olivia Lobby and Showroom | NYC',
      description:
        'Clients and guests to Alice & Olivia’s headquarters in New York City are greeted by this freestanding statement wall, designed and built to serve as a dramatic backdrop when you step off the elevator. The mosaic oval-shaped table is a centerpiece in A&O’s showroom and is a complement to the client’s fun and colorful brand.',
      materials: [
        'Dark teak veneer (wall)',
        'white oak veneer',
        '& custom tiles (table)',
      ],
      images: [image1A, image2A, image3A],
    },
    {
      headerText:
        'Bklyn Commons Co-working Spaces, Bushwick & Flatbush | Brooklyn NY',
      description:
        'Bklyn Commons’ two locations each feature a striking reception desk uniquely designed and built to greet members and visitors to their workspace. Each stands as its own statement piece, built using sustainable walnut, maple, and black laminate. Bklyn Commons’ growth has nurtured a continuous relationship in which we continue to fulfill their needs - the project also consists of 500 working desks built from birch ply and walnut (not shown).',
      materials: ['Maple & walnut', 'hardwood'],
      images: [image1B, image2B, image3B, image4B, image5B, image6B],
    },
    {
      headerText: 'Metro Drafting & Design Ltd | Brooklyn NY',
      description:
        'Reception desk features HerlanderArt’s signature look: etched design - a method that transforms a flat surface into a multi-dimensional texture. Walnut conference table and 500 maple desks (not shown) were included in the project',
      materials: ['Maple (reception desk)', 'walnut (conference table)'],
      images: [image1M, image2M],
    },
  ],
  retailData: [
    {
      headerText: 'Century 21 Stores Herald Square Pop-up | NYC',
      description:
        'We collaborated with C21 Stores to curate an Instagrammable holiday party scene that would entice customers into their holiday pop-up store. The experience aligned with their campaign’s luxe red and gold accents, and included HerlanderArt’s etched design mirror frame, as well as interactive hanging fashion silhouettes and props.',
      materials: ['Curated props'],
      images: [image1RC, image2RC, image3RC, image4RC, image5RC],
    },
    {
      headerText: 'Ballers USA Inc | Harlem, NY',
      description:
        'We collaborated with the owner of Ballers USA to bring a cool, trendy, hip, store to life in the heart of Harlem - from store design fabrication, from customer experience to merchandise display layout, every store fixture was custom made.  Cash wrap HerlanderArt’s etched design; as well as, custom denim and shoe displays',
      materials: ['Oak ply, maple, and locally-', 'sourced walnut hardwood'],
      images: [image1RB, image2RB, image3RB, image4RB],
    },
    {
      headerText: 'Fourteenjay Salon | Tribeca, NY',
      description:
        'This hair salon called for custom furniture to complement its clean and sleek look with a hickory hardwood stairway enclosure, cash wrap, station shelving, and novelty bench with built-in charging stations.  Additional custom pieces include a serving station and magazine rack (not shown) as well as many other custom pieces as the shop expanded.',
      materials: [
        'Hickory hardwood (stairway enclosure)',
        'veneer (cash wrap)',
        'domestic walnut (novelty bench/cash wrap)',
        'Recycled black leather (upholstered bench)',
      ],
      images: [image1RF, image2RF, image3RF, image4RF, image5RF, image6RF],
    },
    {
      headerText: 'Alice and Olivia Retail Store | Aspen, CO',
      description:
        'We worked with Alice and Olivia’s Visuals Team to create a three rainbow wall as a dramatic backdrop inside their newest location in Aspen.  The same color scheme and color-stained was applied to the cash wrap, which was locally fabricated then shipped to Colorado where we installed both pieces.',
      materials: [
        'Soft pine wood finished',
        'rubio monocoat outdoor color stains',
      ],
      images: [image1RA, image2RA, image3RA],
    },
    {
      headerText: 'Sofi’s Color Lounge | Cranford, Westfield & Summit, NJ',
      description:
        'Our work at Sofi’s Color Lounge is a prime example of how we can solve for spacing challenges while not compromising beautiful design.  The cash wrap in the Cranford location features HerlanderArt’s front curved etched design. The blue product display was originally a basement find by the owners, that we transformed into a high-end retail display.',
      materials: [
        'Maple (cash wrap), walnut',
        'hardwood (cash wrap)',
        'ambrosia maple (countertops)',
      ],
      images: [image1RS, image2RS, image3RS, image4RS, image5RS],
    },
  ],
  homeData: [
    {
      headerText: 'The Annalise Coffee Table',
      description:
        'The Annalise Coffee Table was designed to take HerlanderArt’s etched design to the next level - by introducing steam-bending to the etched panels, a sleek curved coffee table was born.  A “floating” top with chamfered edges sits atop angled legs in a pointed oval shape. The table is pictured in walnut hardwood with epoxy.  Custom sizing and material also available.',
      images: [image1HA, image2HA, image3HA, image4HA],
    },
    {
      headerText: 'The Bronx Credenza',
      description:
        'The Bronx Credenza, named after Herlander’s birthplace, is a modern entertainment hub; complete with wine bottle display, storage, and a “floating” plywood edge top.  The credenza is pictured in walnut hardwood and plywood edge.',
      images: [image1HC, image2HC, image3HC, image4HC],
    },
    {
      headerText: 'The Stony Point Standing Desk',
      description:
        'This unique commission was built for a client who challenged us to redefine what a standing desk can be in a home office. With plenty of storage below and a perfectly-sized top for a large laptop, we believe we overachieved our client’s expectation. This project included a complimentary home desk (not pictured). The Stony Point standing desk is seen here in walnut hardwood/walnut veneer.',
      images: [image1HS, image2HS],
    },
  ],
  fashionData: [
    {
      headerText: 'Keith Haring x Alice and Olivia Launch Event | NYC',
      description:
        'We sourced 23 vintage TVs to design this cool art display for Alice & Olivia’s collaboration launch with the Keith Haring Foundation. Project also involved a half-pipe skateboard assembly for an exhibition.',
      images: [image1FH, image2FH, image3FH],
    },
    {
      headerText: 'Alice and Olivia NYFW Fall 2020 | NYC',
      description:
        'This collaboration included multiple prop sets including a large green 30’x10’ dramatic wall backdrop, a pink Moroccan-inspired archway, and a vintage luxury train interior inspired by the Orient Express. ',
      images: [
        image1FA20,
        image2FA20,
        image3FA20,
        image6FA20,
        image5FA20,
        image4FA20,
      ],
    },
    {
      headerText:
        'Booking.com Addams Family-Themed Event | Clinton Hill, Brooklyn',
      description:
        'To coincide with the movie premiere of The Addams Family in October 2019, Booking.com transformed a Brooklyn brownstone to the Addams Family house. For this experience, we built gold-leafed picture frames, Wednesday’s signature guillotine bed and window shutters, and an animal cage (not shown).',
      images: [
        image1FB,
        image2FB,
        image3FB,
        image4FB,
        image5FB,
        image6FB,
        image7FB,
      ],
    },
    {
      headerText: 'Alice and Olivia NYFW Spring 2019 | NYC',
      description:
        'The production sets for A+O’s SP19 NYFW showcased monochromatic bright color palettes for each scene.  We contributed multiple elements including the yellow pool enclosure and flooring, yellow archway, the mint green brick kitchen facade, and purple velvet mountain.',
      images: [
        image1FA19,
        image2FA19,
        image3FA19,
        image4FA19,
        image5FA19,
        image6FA19,
      ],
    },
    {
      headerText: 'Alice and Olivia NYFW Fall 2018 | NYC',
      description:
        'Empowerment of women and education was the theme for this fashion show.  Every single prop book - oversized, life-sized, and miniature-sized - was custom made, along with the rainbow library bookshelves and center mantle.',
      images: [
        image1FA18,
        image2FA18,
        image3FA18,
        image4FA18,
        image5FA18,
        image6FA18,
      ],
    },
    {
      headerText: 'Alice and Olivia NYFW Spring 2017 | NYC',
      description:
        'For one of our favorite A+O shows, HerlanderArt collaborated with the Visuals Team to build multi-leveled grassy knoll stages covered with real moss and grass featuring an inverted rooftop framed house, and the lifesize open prop book with moving pages. ',
      images: [image1FA17, image2FA17, image3FA17, image4FA17],
    },
  ],
};
